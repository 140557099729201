.GameCard {
  border-radius: 20px;
  overflow: hidden;
  margin: var(--card-margin);
  cursor: pointer;
}

.GameCard_size_s {
  width: var(--card-size-s);
  height: var(--card-size-s);
}

.GameCard_size_m {
  width: var(--card-size-m);
  height: var(--card-size-m);
}

.GameCard_size_l {
  width: var(--card-size-l);
  height: var(--card-size-l);
}

.GameCard-Inner {
  width: 100%;
  height: 100%;
  border-radius: 20px;
  overflow: hidden;
  position: relative;
}

.GameCard-Inner:hover .GameCard-Overlay {
  display: flex;
}

.GameCard-Poster {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.GameCard-Overlay {
  display: none;
  position: absolute;
  inset: 0;
  flex-direction: column;
  justify-content: space-between;
}

@media (max-width: 600px) {
  .GameCard-Overlay {
    display: none !important;
  }
}

.GameCard-OverlayTop {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
}

.GameCard-OverlayTags {
  display: flex;
  gap: 4px;
  align-items: center;
}

.GameCard-SocialIcon {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #783ada;
  flex-shrink: 0;
}

.GameCard-Tag {
  padding: 0 8px;
  height: 24px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  border-radius: 14px;
  background-color: #783ada;
  flex-shrink: 0;
}

.GameCard-Views {
  display: flex;
  align-items: center;
  gap: 6px;
  border-radius: 14px;
  height: 24px;
  background: rgba(0, 0, 0, 0.4);
  padding: 0 8px;
}

.GameCard-OverlayBottom {
  height: 50%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 16px;
}

.GameCard-Name {
  text-overflow: ellipsis;
  overflow-x: hidden;
  width: 70%;
  white-space: nowrap;
}

.GameCard-Link {
  display: block;
  position: absolute;
  inset: 0;
}
