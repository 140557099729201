.monaco-scrollable-element > .scrollbar > .slider {
  background: #9f92ff !important;
  width: 6px !important;
  left: 8px !important;
}
.monaco-editor {
  padding: 10px 0 0 0 !important;
}

.monaco-editor .vertical {
  box-shadow: none !important;
}
.monaco-editor .scroll-decoration {
  box-shadow: none !important;
}
.decorationsOverviewRuler {
  width: 6px !important;
  background: rgba(255, 255, 255, 0.2) !important;
}
