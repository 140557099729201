.sketch-picker {
  background-color: red;
}

.color-picker-swatch {
  cursor: pointer;
}

.color-picker-popover {
  position: absolute;
  z-index: 2;
}

.color-picker-cover {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}