.OcclusionCullingDebugger {
  position: absolute;
  top: 100vh;
  left: 0;
}

.OcclusionCullingCanvas {
  position: absolute;
  left: -99999px;
}
