input:-webkit-autofill {
    -webkit-transition: background-color 5000s ease-in-out 0s;
    transition: background-color 5000s ease-in-out 0s;
    color: #fff;
    -webkit-text-fill-color: #fff;
}

.MuiAlert-action,
.MuiAlert-message,
.MuiMenu-list {
    margin: 0 !important;
    padding: 0 !important;
}

.MuiBackdrop-root {
    background-color: rgba(0, 0, 0, 0.1) !important;
}

.css-5o13hu-MuiStack-root > :not(style) + :not(style) {
    margin-left: 0 !important;
}

.css-1pxutw3-MuiSlider-thumb::after {
    width: 22px !important;
    height: 22px !important;
}

.css-1gv0vcd-MuiSlider-track {
    border: none !important;
}

.css-14pt78w-MuiSlider-rail {
    background: rgba(255, 255, 255, 0.2) !important;
    opacity: 1 !important;
}

.css-1pxutw3-MuiSlider-thumb.Mui-active {
    box-shadow: 0 0 0 8px rgb(244 244 244 / 16%) !important;
}

.css-1pxutw3-MuiSlider-thumb {
    width: 15px !important;
    height: 15px !important;
}

.css-1x2e00o-MuiSwitch-root .MuiSwitch-switchBase.Mui-checked {
    color: #d9d9d9 !important;
    opacity: 1 !important;
}

.css-1x2e00o-MuiSwitch-root .MuiSwitch-switchBase {
    opacity: 0.4 !important;
}

.makeStyles-settingBox-85 .css-1bu5m7t-MuiButtonBase-root-MuiButton-root {
    background: transparent !important;
}

.makeStyles-settingBox-21 .makeStyles-iconsItem-24 {
    flex: 0 0 16px;
    width: 16px;
    height: 16px;
}

.css-j204z7-MuiFormControlLabel-root {
    margin: 0 !important;
}

.MuiAlert-action {
    cursor: pointer;
}

.css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
    box-shadow: none !important;
}

.is-hidden {
    overflow: hidden;
}

html {
    scroll-behavior: smooth;
}

body > iframe {
    display: none;
}
